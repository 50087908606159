<template>
  <div>
    <h2 class="section-header">
      <svg
        width="33"
        height="25"
        viewBox="0 0 40 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.1 9.16615L0.462 8.48782L0 8.73782V9.16615H1.1ZM16.5 0.83282L17.138 0.154486C16.9517 0.0539995 16.7287 0 16.5 0C16.2713 0 16.0483 0.0539995 15.862 0.154486L16.5 0.83282ZM31.9 9.16615H33V8.73782L32.538 8.48782L31.9 9.16615ZM5.5 14.1662V13.3328H4.4V14.1662H5.5ZM14.3 14.1662H15.4V13.3328H14.3V14.1662ZM2.2 24.9995V9.16615H0V24.9995H2.2ZM1.738 9.84449L17.138 1.51115L15.862 0.154486L0.462 8.48782L1.738 9.84449ZM15.862 1.51115L31.262 9.84449L32.538 8.48782L17.138 0.154486L15.862 1.51115ZM30.8 9.16615V24.9995H33V9.16615H30.8ZM6.6 24.9995V14.1662H4.4V24.9995H6.6ZM5.5 14.9995H14.3V13.3328H5.5V14.9995ZM13.2 14.1662V24.9995H15.4V14.1662H13.2ZM11 19.9995H14.3V18.3328H11V19.9995ZM24.2 13.3328V19.9995H26.4V13.3328H24.2ZM28.6 3.33282V7.49949H30.8V3.33282H28.6Z"
          fill="#000000"
        />
      </svg>
      Properties <span class="num-properties">({{ numProperties }})</span>
    </h2>
    <!--    start filter section-->
    <div class="filter-section mt-5 py-4 px-5 d-lg-block d-none">
      <div class="row">
        <div class="col-lg-3">
          <div class="form-group w-100">
            <label for="" class="filter-label">Filter by property type</label>
            <select type="text" class="form-control filter-select">
              <option value="" hidden>Property Type</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group w-100">
            <label for="" class="filter-label">Filter by Category</label>
            <select type="text" class="form-control filter-select">
              <option value="" hidden>Category</option>
              <option value="1">Buy</option>
              <option value="2">Rent</option>
            </select>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group w-100">
            <label for="" class="filter-label">Search</label>
            <input
              v-model="searchQuery"
              type="search"
              class="form-control filter-select"
              placeholder="Search for properties"
            />
          </div>
        </div>
        <div class="col-lg-2">
          <div class="form-group float-right">
            <label class="filter-label">Layout</label>
            <div class="d-flex">
              <div
                class="layout-select mr-3 d-flex align-items-center justify-content-center"
                :class="[activeLayout === 'card' ? 'layout-select-active' : '']"
                @click="switchToCardLayout"
              >
                <img
                  v-if="activeLayout === 'card'"
                  src="../assets/images/card-layout-active.svg"
                  alt=""
                />
                <img v-else src="../assets/images/card-layout.svg" alt="" />
              </div>
              <div
                class="layout-select d-flex align-items-center justify-content-center"
                :class="[activeLayout === 'list' ? 'layout-select-active' : '']"
                @click="switchToListLayout"
              >
                <img
                  v-if="activeLayout === 'list'"
                  src="../assets/images/list-layout-active.svg"
                  alt=""
                />
                <img v-else src="../assets/images/list-layout.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-lg-none mt-5 mb-5 d-flex justify-content-between align-items-center"
    >
      <input
        v-model="searchQuery"
        type="search"
        class="form-control filter-select mr-5"
        placeholder="Search for properties"
      />
      <div class="d-flex align-items-center" @click="filterModal">
        <i class="fas fa-sliders-h mr-3"></i> Filter
      </div>
    </div>
    <!--    end filter section-->

    <!--    start main content-->
    <div class="mt-5">
      <div class="text-center"  v-if="fetching">
        <Loader />
      </div>
      <div v-else>
        <div class="row" v-if="activeLayout === 'card'">
          <div
            class="col-lg-4 col-md-6 property"
            v-for="(property, index) in resultQuery"
            :key="index"
          >
            <div class="property-type-wrapper">
              <div
                class="property-type property-type-sale"
                :class="[
                  property.businesstypename === 'Rent'
                    ? 'property-type-rent'
                    : 'property-type-sale'
                ]"
              >
                <span v-if="property.businesstypename === 'Rent'">
                  For Rent
                </span>
                <span v-else> For Sale</span>
              </div>
            </div>
            <div
              class="card p-3 op-wrapper mb-5"
              :class="[
                property.businesstypename === 'Rent'
                  ? 'op-wrapper-rent'
                  : 'op-wrapper-sale'
              ]"
            >
              <div class="card ip-wrapper">
                <img
                  :src="property.oneimageurl"
                  class="card-img-top"
                  alt=""
                  style="height: 200px"
                />
                <div class="card-body">
                  <p class="name">{{ property.title }}</p>
                  <div class="d-flex align-items-center">
                    <img
                      class="location-icon"
                      src="../assets/images/property-location.svg"
                      alt=""
                    />
                    <span class="location">
                      {{ property.city }}, {{ property.state }}
                    </span>
                  </div>
                  <p class="description">
                    {{ property.description }}
                  </p>
                  <p v-if="property.businesstypename === 'Rent'" class="price">
                    ₦ {{ parseFloat(property.price).toLocaleString() }}
                    <!-- {{ property.modeofpayment }} -->
                  </p>
                  <p v-else class="price">
                    ₦ {{ parseFloat(property.price).toLocaleString() }}
                  </p>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="d-flex flex-column align-items-center stat">
                      <img
                        class="stat-img"
                        src="../assets/images/bed.svg"
                        alt=""
                      />
                      <span class="mt-2">
                        {{ property.bedrooms }}
                      </span>
                    </div>
                    <div class="d-flex flex-column align-items-center stat">
                      <img
                        class="stat-img"
                        src="../assets/images/bath.svg"
                        alt=""
                      />
                      <span class="mt-2">
                        {{ property.bathrooms }}
                      </span>
                    </div>
                    <div class="d-flex flex-column align-items-center stat">
                      <img
                        style="width: 15px"
                        src="../assets/images/toilet.svg"
                        alt=""
                      />
                      <span class="mt-1">
                        {{ property.toilets }}
                      </span>
                    </div>
                    <div class="d-flex flex-column align-items-center stat">
                      <img
                        style="width: 20px"
                        src="../assets/images/parking.svg"
                        alt=""
                      />
                      <span class="mt-1">
                        {{ property.parking }}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div class="text-center">
                    <button
                      class="btn btn-primary share-btn"
                      @click="socialShare(property)"
                    >
                      <img
                        class="share-btn-img"
                        src="../assets/images/share.svg"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div
            class="col-12 mb-4"
            v-for="(property, index) in resultQuery"
            :key="index"
          >
            <div class="card list-view-wrapper">
              <div class="row no-gutters">
                <div class="col-md-2 img-wrapper">
                  <img
                    style="width: 100%; height: 150px"
                    :src="property.oneimageurl"
                    alt=""
                  />
                  <div
                    v-if="property.businesstypename === 'Rent'"
                    class="list-indicator list-indicator-rent"
                  >
                    R
                  </div>
                  <div v-else class="list-indicator list-indicator-sale">
                    S
                  </div>
                </div>
                <div class="col-md-10">
                  <div class="row h-100 pl-4">
                    <div class="col h-100 d-flex align-items-center">
                      <div class="">
                        <p class="pin mb-0">
                          <strong>PIN:</strong>
                          {{ property.pin }}
                        </p>
                        <p>
                          {{ property.title }}
                        </p>
                      </div>
                    </div>
                    <div class="col h-100 d-flex align-items-center">
                      <div v-if="property.businesstypename === 'Rent'" class="">
                        <p class="price mb-0">
                          ₦ {{ parseFloat(property.price).toLocaleString() }}
                        </p>
                        <p class="text-muted mt-0">
                          {{ property.modeofpayment }}
                        </p>
                      </div>
                      <div v-else class="">
                        <p class="price mb-0">
                          ₦ {{ parseFloat(property.price).toLocaleString() }}
                        </p>
                      </div>
                    </div>
                    <div class="col h-100 d-flex align-items-center">
                      <div class="d-flex flex-column align-items-center">
                        <img
                          class="location-icon location-list-view mt-2"
                          src="../assets/images/property-location.svg"
                          alt=""
                        />
                        <p class="location location-list-view">
                          {{ property.city }}, {{ property.state }}
                        </p>
                      </div>
                    </div>
                    <div class="col h-100 d-flex align-items-center">
                      <div class="d-flex flex-column">
                        <div class="d-flex">
                          <div
                            class="d-flex flex-column align-items-center mr-3"
                          >
                            <img
                              style="width: 25px"
                              src="../assets/images/bed.svg"
                              alt=""
                            />
                            <span style="font-size: 12px"
                              >{{ property.bedrooms }} bedroom</span
                            >
                          </div>
                          <div class="d-flex flex-column align-items-center">
                            <img
                              style="width: 25px"
                              src="../assets/images/bath.svg"
                              alt=""
                            />
                            <span style="font-size: 12px"
                              >{{ property.bathrooms }} bathroom</span
                            >
                          </div>
                        </div>
                        <div class="d-flex mt-1">
                          <div
                            class="d-flex flex-column align-items-center pl-2 mr-4"
                          >
                            <img
                              style="width: 15px"
                              src="../assets/images/toilet.svg"
                              alt=""
                            />
                            <span style="font-size: 12px"
                              >{{ property.toilets }} toilet</span
                            >
                          </div>
                          <div
                            class="d-flex flex-column align-items-center pl-3"
                          >
                            <img
                              style="width: 20px"
                              src="../assets/images/parking.svg"
                              alt=""
                            />
                            <span style="font-size: 12px"
                              >{{ property.parking }} parking</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col h-100 d-flex align-items-center">
                      <button
                        @click="viewProperty(property)"
                        class="btn btn-primary view-btn"
                      >
                        View
                      </button>
                    </div>
                    <div class="col h-100 d-flex align-items-center">
                      <button
                        class="btn btn-primary share-btn"
                        @click="socialShare(property)"
                      >
                        <img
                          class="share-btn-img"
                          src="../assets/images/share.svg"
                          alt=""
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="share-modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center">
            <p>Please select a network below to share this property.</p>
            <div class="d-flex flex-column">
              <ShareNetwork
                class="mb-3"
                network="email"
                :url="sharing.url"
                :title="sharing.title"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
              >
                <button class="btn btn-outline-dark">
                  <i class="far fa-envelope mr-2"></i>
                  <span>Share on Email</span>
                </button>
              </ShareNetwork>
              <ShareNetwork
                class="mb-3"
                network="whatsapp"
                :url="sharing.url"
                :title="sharing.title"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
              >
                <button class="btn btn-outline-dark">
                  <i class="fab fa-whatsapp mr-2"></i>
                  <span>Share on WhatsApp</span>
                </button>
              </ShareNetwork>
              <ShareNetwork
                class="mb-3"
                network="twitter"
                :url="sharing.url"
                :title="sharing.title"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
              >
                <button class=" btn btn-outline-dark">
                  <i class="fab fa-twitter mr-2"></i>

                  <span>Share on Twitter</span>
                </button>
              </ShareNetwork>
              <ShareNetwork
                class="mb-3"
                network="facebook"
                :url="sharing.url"
                :title="sharing.title"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
              >
                <button class="btn btn-outline-dark">
                  <i class="fab fa-facebook-f mr-2"></i>
                  <span>Share on Facebook</span>
                </button>
              </ShareNetwork>
              <ShareNetwork
                class="mb-3"
                network="linkedin"
                :url="sharing.url"
                :title="sharing.title"
                :quote="sharing.quote"
                :hashtags="sharing.hashtags"
              >
                <button class="btn btn-outline-dark">
                  <i class="fab fa-linkedin-in mr-2"></i>
                  <span>Share on LinkedIn</span>
                </button>
              </ShareNetwork>
            </div>

            <div>
              <button
                type="button"
                class="btn btn-secondary float-right"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="filter-modal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="" class="filter-label"
                    >Filter by property type</label
                  >
                  <select type="text" class="form-control filter-select">
                    <option value="" hidden>Property Type</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="" class="filter-label">Filter by Category</label>
                  <select type="text" class="form-control filter-select">
                    <option value="" hidden>Category</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import properties from "../services/properties";
import Loader from "./Loader";
import "@fortawesome/fontawesome-free/css/all.css";

export default {
  components: {
    Loader
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.properties.filter(property => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(
              queryParam =>
                property.title.toLowerCase().includes(queryParam) ||
                property.description.toLowerCase().includes(queryParam)
            );
        });
      } else {
        return this.properties;
      }
    }
  },
  data() {
    return {
      activeLayout: this.$store.getters.getPropertiesActiveLayout,
      fetching: false,
      properties: [],
      numProperties: 0,
      searchQuery: null,
      sharing: {
        url: "",
        title: "",
        description: "",
        quote: "",
        hashtags: ""
      },
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "far fah fa-lg fa-envelope",
          color: "#333333"
        }
      ],
      userCode: null
    };
  },
  mounted() {
    this.userCode = this.$store.getters.getUser.usercode;
    this.fetching = true;
    properties
      .fetchForRent()
      .then(async data => {
        if (data.success === "fetched") {
          const { properties } = data;
          await properties.forEach(property => {
            this.properties.push(property);
          });
        } else {
          this.$toast.error(data.error);
        }
        await properties.fetchForSale().then(async data => {
          if (data.success === "fetched") {
            const { properties } = data;
            await properties.forEach(property => {
              this.properties.push(property);
            });
          } else {
            this.$toast.error(data.error);
          }
        });
      })
      .catch((err) => {
        console.log(err);
        // this.$toast.error("please check your network and refresh the page");
      })
      .finally(() => {
        this.fetching = false;
        this.numProperties = this.properties.length;
      });
    // console.log(this.properties);
  },
  methods: {
    switchToCardLayout() {
      this.$store.dispatch("setPropertiesActiveLayout", "card");
      this.activeLayout = this.$store.getters.getPropertiesActiveLayout;
    },
    switchToListLayout() {
      this.$store.dispatch("setPropertiesActiveLayout", "list");
      this.activeLayout = this.$store.getters.getPropertiesActiveLayout;
    },
    socialShare(property) {
      if (property.businesstypename === "Rent") {
        this.sharing.url = `https://myshelta.com/property/${property.pin}`;
      } else {
        this.sharing.url = `https://sales.myshelta.com/property-details/${property.pin}/${this.userCode}`;
      }
      this.sharing.title = ` ${property.title} on Shelta. Click to view details.`;
      this.sharing.description =
        "We provide a seamless and convenient rental and facility management experience. Too often, renting a home puts property owners and renters in difficult situations regarding their relationship. Shelta offers well-researched, thought out strategies to make an otherwise difficult experience pleasurble for both parties.";

      $("#share-modal").modal("show");
    },
    viewProperty(property) {
      let url;
      if (property.businesstypename === "Rent") {
        url = `https://myshelta.com/property/${property.pin}`;
      } else {
        url = `https://sales.myshelta.com/property-details/${property.pin}/${this.userCode}`;
      }
      window.location = url;
    },
    filterModal() {
      $("#filter-modal").modal("show");
    }
  }
};
</script>

<style scoped>
@import "../styles/section-header.css";

.num-properties {
  color: #ffb100;
  margin-left: 6px;
}
.filter-section {
  background: rgba(0, 47, 215, 0.1);
  border-radius: 5px;
}
.filter-label {
  font-family: "Gotham-Medium", sans-serif;
}
.filter-select {
  height: 45px;
  font-size: 14px;
  font-family: "Gotham-Medium", sans-serif;
}
.layout-select-active {
  background-color: #0033ea !important;
}
.layout-select {
  background-color: #ffffff;
  border-radius: 3px;
  height: 45px;
  width: 45px;
  cursor: pointer;
}
.op-wrapper {
  box-shadow: 0px 10px 20px #e2e2e2;
  border-radius: 5px;
}
.op-wrapper-sale {
  border: 0.3px solid #ffb100;
}
.op-wrapper-rent {
  border: 0.3px solid #0033ea;
}
.ip-wrapper {
  box-shadow: 0px 10px 20px #e2e2e2;
  border-radius: 5px;
}
.name {
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.location-icon {
  width: 12px;
  margin-right: 5px;
}
.location {
  font-family: "Gotham-Medium", sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.25);
}
.location-list-view {
  font-size: 16px !important;
}
.description {
  font-size: 12px;
  color: #6c6c6c;
  margin-top: 10px;
}
.price {
  font-family: "Gotham-Medium", sans-serif;
  font-size: 18px;
}
.stat {
  font-size: 12px;
}
.stat-img {
  width: 25px;
}
.share-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0033ea;
  border-color: #0033ea;
}
.share-btn-img {
  width: 20px;
}
.property-type-wrapper {
  text-align: -webkit-center;
}
.property-type {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Gotham-Medium", sans-serif;
  color: #ffffff;
}
.property-type-sale {
  background: linear-gradient(180deg, #ffb100 0%, #de9b03 99.48%);
}
.property-type-rent {
  background: linear-gradient(180deg, #6886f0 0%, #0033ea 99.48%);
}
.pin {
  font-size: 13px;
}
.list-view-wrapper {
  border: 0.2px solid #0033ea;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgb(0 0 0 / 5%);
}
.list-view-container {
  position: relative;
}
.list-view-content {
  margin: 0;
  position: absolute;
  top: 70%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.view-btn {
  background-color: #0033ea;
  border-radius: 5px;
  width: 120px;
  height: 50px;
}
.img-wrapper {
  position: relative;
}
.list-indicator {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  position: absolute;
  bottom: 70%;
  left: 5%;
}
.list-indicator-rent {
  background: #0033ea;
}
.list-indicator-sale {
  background: #ffb100;
}
</style>
